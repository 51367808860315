import React from "react";
import Button from "./Common/Button";
import { App as CapacitorApp } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";

const BackButton = () => {
  const platformName = Capacitor.getPlatform();
  if (platformName !== "ios") {
    return null;
  }

  const handleBackClick = () => {
    const pageName = localStorage.getItem("pageName");
    if (window.history.length > 1 && pageName !== "checkInQR") {
      window.history.back();
    } else if (pageName !== "checkInQR") {
      CapacitorApp.exitApp();
    }
  };
  return (
    <div>
      <Button classname="back-button" onClick={handleBackClick}>
        <i class="bi bi-arrow-left back-button "> Back</i>
      </Button>
    </div>
  );
};

export default BackButton;
