import { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { userDetailsContext } from "../context";
import { logout } from "../Utils/login";

const useAccess = (setCompletedStatus) => {
  const { role, activitiesresult = [] } = useContext(userDetailsContext);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    let branchCompleted = false;
    let employeeCompleted = false;

    activitiesresult.forEach((activity) => {
      if (activity.id === 2 && activity.iscompleted === 1) {
        branchCompleted = true;
      }
      if (activity.id === 3 && activity.iscompleted === 1) {
        employeeCompleted = true;
      }
    });

    if (activitiesresult.length && branchCompleted && location.pathname.toLocaleLowerCase() === "/newbranch") {
      setCompletedStatus(true);
    } else if (activitiesresult.length && !branchCompleted && location.pathname.toLocaleLowerCase() === "/newbranch") {
      setCompletedStatus(false);
    }

    if(activitiesresult.length && employeeCompleted && location.pathname.toLocaleLowerCase() === "/newemployee"){
      setCompletedStatus(true);    }
    else if(activitiesresult.length && !employeeCompleted && location.pathname.toLocaleLowerCase()==="/newemployee"){
        setCompletedStatus(false);
      }
  }, [activitiesresult]);
};

export default useAccess;
