import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Form from "../Components/Common/Form";
import Status from "../Components/Common/Status";
import Actions from "../Components/Common/Actions";
import { getBranch } from "../services/branch";
import { getTeams } from "../services/team";
import { getShifts } from "../services/shift";
import { getDesignationList } from "../services/designation";
import { getCheckinTypes } from "../services/checkinTypes";
import { createEmployee as createEmployeeService } from "../services/employee";
import { validation } from "../Utils/validation";
import { getActivity } from "../services/dashboard";
import moment from "moment";
import PageHeader from "../Components/Common/PageHeader";
import useAccess from "../Utils/useAccess";

const NewEmployee = ({ setActivities }) => {
  const router = useHistory();
  const [employees, setEmployees] = useState([
    {
      firstName: "",
      lastName: "",
      email: "",
      checkinType: 1,
      gps: 1,
      branch: { id: 1 },
      team: { id: 1 },
      designation: { id: "" },
      shift_id: 1,
      leaveType: { id: "" },
      status: 1,
      branchRoles: [],
      teamRoles: [],
      timezone: "",
    },
  ]);

  const [branchList, setBranchList] = useState([]);
  const [teamList, setTeamList] = useState([]);
  const [shiftList, setShiftList] = useState([]);
  const [designationList, setDesignationList] = useState([]);
  const [checkinTypes, setCheckinTypes] = useState([]);
  const [errorMessages, setErrorMessages] = useState({});
  const [serverErrorMessage, setServerErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState({
    showStatus: false,
    spinner: false,
    success: false,
    successMessage: "",
    intialrender: false,
  });

  const setCompletedStatus = (statusValue) => {
    const updatedStatus = { ...status };
    if (statusValue) {
      updatedStatus.success = true;
      updatedStatus.successMessage =
        "Employee was already created successfully";
    }
    setStatus({
      ...updatedStatus,
      showStatus: statusValue,
      intialrender: true,
    });
  };

  useAccess(setCompletedStatus);

  const [personalInformation, setPersonalInformation] = useState({
    col1: [],
  });

  useEffect(() => {
    getBranch({ total: 100, itemsPerPage: 100, page: 1 }).then((results) => {
      setBranchList(results.data.result || []);
    });

    getTeams({ total: 100, itemsPerPage: 100, page: 1 }).then((results) => {
      setTeamList(results.data.result || []);
    });

    getShifts({ total: 100, itemsPerPage: 100, page: 1 }).then((results) => {
      setShiftList(results.data.result || []);
    });

    getDesignationList({ total: 100, itemsPerPage: 100, page: 1 }).then(
      (results) => {
        setDesignationList(results.data.result || []);
      }
    );

    getCheckinTypes().then((results) => {
      setCheckinTypes(results.data || []);
    });
  }, []);

  const onChangeHandler = (event, key, index) => {
    const updatedEmployees = [...employees];
    updatedEmployees[index][key] = event.target.value;
    setEmployees(updatedEmployees);
  };

  const addEmployee = () => {
    if (!validateEmployees()) return;

    if (employees.length < 4) {
      setEmployees([
        ...employees,
        {
          firstName: "",
          lastName: "",
          email: "",
          checkinType: 1,
          gps: 1,
          branch: { id: 1 },
          team: { id: 1 },
          designation: { id: "" },
          shift_id: 1,
          leaveType: { id: "" },
          status: 1,
          branchRoles: [],
          teamRoles: [],
          timezone: "",
        },
      ]);
    }
  };

  const onDeleteEmployee = (index) => {
    if (employees.length <= 1) return;

    const updatedEmployees = employees.filter((_, idx) => idx !== index);
    setEmployees(updatedEmployees);
  };

  const validateEmployees = () => {
    let allValid = true;
    const allErrors = {};

    employees.forEach((employee, index) => {
      const { errorMessage, isValid } = validation(
        personalInformation.col1,
        employee
      );
      if (!isValid) {
        allValid = false;
        allErrors[index] = errorMessage;
      }
    });

    setErrorMessages(allErrors);
    return allValid;
  };

  const createEmployee = async () => {
    if (!validateEmployees()) return;
    setIsLoading(true);
    setStatus({ ...status, showStatus: true, spinner: true });

    try {
      const dateObject = new Date().getTimezoneOffset();
      let utc = -Math.round(dateObject / 60) + ":" + -(dateObject % 60);
      utc = utc < 0 ? utc : "+" + utc;

      const data = employees.map((employee) => ({
        first_name: employee.firstName,
        last_name: employee.lastName,
        mail_id: employee.email,
        checkin_type_id: employee.checkinType,
        gps: employee.gps,
        workspace_id: employee.branch.id,
        team_id: employee.team.id,
        designation_id: employee.designation.id,
        shift_id: employee.shift_id,
        leave_type_id: employee.leaveType.id,
        status: employee.status,
        branch_roles: employee.branchRoles,
        team_roles: employee.teamRoles,
        timezone: utc,
      }));

      await Promise.all(
        data.map((employee) => createEmployeeService(employee))
      );

      setStatus({
        ...status,
        showStatus: true,
        spinner: false,
        success: true,
        successMessage: `Employee Created Succesfully`,
        intialrender: true,
      });
    } catch (error) {
      console.error("Error creating employees:", error);
      setServerErrorMessage("Something went wrong. Please try again.");
      setStatus({
        ...status,
        showStatus: false,
        spinner: false,
        success: false,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const updateLayout = () => {
      if (window.innerWidth < 768) {
        // Mobile Layout
        setPersonalInformation({
          col1: [
            { row: [{ type: "text", key: "firstName", required: true }] },
            { row: [{ type: "text", key: "lastName", required: true }] },
            {
              row: [
                {
                  type: "email",
                  key: "email",
                  validationType: "email",
                  required: true,
                },
              ],
            },
          ],
        });
      } else {
        // Web Layout
        setPersonalInformation({
          col1: [
            {
              row: [
                { type: "text", key: "firstName", required: true },
                { type: "text", key: "lastName", required: true },
                {
                  type: "email",
                  key: "email",
                  validationType: "email",
                  required: true,
                },
              ],
            },
          ],
        });
      }
    };

    updateLayout();
  }, []);

  const actions = [
    {
      label: isLoading ? "Loading..." : "Complete",
      className: "btn-primary",
      onClick: () => createEmployee(),
      disabled: isLoading,
    },
  ];

  return (
    <>
      <PageHeader title="Add Employees (Max 4)" showBackButton={false} />
      {status.intialrender ? (
        status.showStatus ? (
          <div className="mb-4">
            <div
              className={
                status.showStatus ? "d-block status-spinner" : "d-none"
              }
            >
              <Status
                spinner={status.spinner}
                success={status.success}
                successMessage={status.successMessage}
                onClick={async () => {
                  const updatedActivity = await getActivity();
                  setActivities(updatedActivity.data.result);
                  setStatus({ showStatus: false });
                  router.push("/dashboard");
                }}
              >
                {status.success && (
                  <div className="d-flex justify-content-center align-items-center shadow p-4 pt-0 pb-0 mb-4 bg-white border-bottom shadow-sm">
                    <div className="text-center">
                      <br />
                      <h5>✅ Branch & Employees Setup Completed!</h5>
                      <br />
                      <p>
                        📌 Next Steps:
                        <br />
                        <br />
                        1. Download & Print Branch QR Code
                        <br />
                        Employees will scan this QR code at the branch to check
                        in/out.
                        <br />
                        <br />
                        2. Ask Employees to Log In
                        <br />
                        Employees should use the credentials sent to them.
                        <br />
                        <br />
                        3. Start Scanning!
                        <br />
                        Employees must scan the branch QR code from their mobile
                        to mark attendance.
                      </p>
                    </div>
                  </div>
                )}
              </Status>
            </div>
          </div>
        ) : (
          <form>
            {employees.map((employee, index) => (
              <div key={index} className="row mb-3 mt-3 mx-3">
                <div className="col-11 border p-3 rounded">
                  <Form
                    rows={personalInformation.col1}
                    value={employee}
                    errorMessage={errorMessages[index] || {}}
                    onChange={(e, key) => onChangeHandler(e, key, index)}
                    isLoading={isLoading}
                  />
                </div>
                <div className="col-1 d-flex align-items-center">
                  <button
                    type="button"
                    className={`btn btn-secondary p-1 me-2 ${
                      employees.length <= 1 ? "disabled" : ""
                    }`}
                    onClick={() => onDeleteEmployee(index)}
                  >
                    <i className="bi bi-dash fs-4"></i>
                  </button>
                </div>
              </div>
            ))}
            {!status.success && !status.spinner && (
              <>
                <div className="pt-3 pb-3 px-4 d-flex justify-content-center">
                  <button
                    type="button"
                    className="btn btn-sm btn-primary mx-2"
                    onClick={addEmployee}
                  >
                    <i className="bi bi-plus fs-5"></i>
                  </button>
                </div>

                <div className="pt-3 pb-3 px-4 d-flex justify-content-end">
                  <Actions actions={actions} />
                </div>
              </>
            )}
          </form>
        )
      ) : null}
    </>
  );
};

export default NewEmployee;
