import * as FileSaver from 'file-saver';
import { Capacitor } from '@capacitor/core';
import { downloadFileOnAndroid } from '../Utils/download';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable'; 
import Button from './Common/Button';

const PdfExport = ({ pdfData, fileName, exportKeys, label = "Export to PDF" , className=""}) => {

  const platformName = Capacitor.getPlatform();

  // Transform data for exporting
  const newPdfData = pdfData.map((data) => {
    const newData = {};
    exportKeys.forEach(element => {
      newData[element.label || element.key] = data[element.key];
    });
    return newData;
  });

  // Export to PDF functionality
  const exportToPDF = async () => {
    const doc = new jsPDF();


    // Prepare header 
    const header = exportKeys.map(element => element.label || element.key);

    // Prepare table body data
    const body = newPdfData.map(item => 
      exportKeys.map(element => item[element.label || element.key])
    );

    // Use jsPDF's autoTable method to add the table
    doc.autoTable({
      head: [header],
      body: body,
      startY: 20,  
      theme: 'striped',  
      margin: { top: 20 },
      headStyles: {
        fillColor: [29, 25, 216],
        textColor: [255, 255, 255], 
        fontSize: 9, 
        halign: 'left', 
      },
      styles:{
        fontSize:9
      }
      
    });

    // Save or download PDF
    if (platformName === 'android' || platformName === 'ios') {
      const pdfOutput = doc.output('blob');
      downloadFileOnAndroid(pdfOutput, fileName + new Date().getTime() + '.pdf');
    } else if (platformName === 'web') {
      const pdfOutput = doc.output('blob');
      FileSaver.saveAs(pdfOutput, fileName + new Date().getTime() + '.pdf');
    }
  };

  return (
    <Button className={`btn btn-success btn-sm ${className}`} onClick={exportToPDF}>{label}</Button>
  );
};

export default PdfExport;
