import axios from "axios"
const BaseUrl = process.env.REACT_APP_BASEURL;

export const getEmployeesList = (params) => {
   const url = BaseUrl+'employees' 
   const token = localStorage.getItem ('token') || ''
   return axios ( {
       method : 'get',
       url,
       headers : {
           Authorization : 'Bearer '+token 
       },
       params
   } )
}

export const getEmployeesListService = (id) => {
    const url = BaseUrl+'employees/'+ id;
   const token = localStorage.getItem ('token') || ''
   return axios ( {
       method : 'get',
       url,
       headers : {
           Authorization : 'Bearer '+token 
       }
   } )
}

export const getEmployeesListByKeyword = (keyword) => {
    const url = BaseUrl+'employees/autocomplete/'+keyword 
    const token = localStorage.getItem ('token') || ''
    return axios ( {
        method : 'get',
        url,
        headers : {
            Authorization : 'Bearer '+token 
        }
    } )
 }

export const getShiftDetail = (id) => {
    const url = BaseUrl+'employees/shiftDetail/'+ id;
   const token = localStorage.getItem ('token') || ''
   return axios ( {
       method : 'get',
       url,
       headers : {
           Authorization : 'Bearer '+token 
       }
   } )
}

export const createEmployee = (data) => {
    const url = BaseUrl+'employees' 
    const token = localStorage.getItem ('token') || ''
    return axios ( {
        method : 'post',
        url,
        headers : {
            Authorization : 'Bearer '+token 
        },
        data
    } )
}

export const updateEmployee = (data, id) => {
    const url = BaseUrl+'employees/'+id 
    const token = localStorage.getItem ('token') || ''
    return axios ( {
        method : 'put',
        url,
        headers : {
            Authorization : 'Bearer '+token 
        },
        data
    } )
}

export const deleteEmployee = (id)=> {
    const url = BaseUrl+'employees/'+id 
    const token = localStorage.getItem ('token') || ''
    return axios ( {
        method : 'delete',
        url,
        headers : {
            Authorization : 'Bearer '+token 
        }
    } )
}


export const removeDevice = (id)=> {
    const url = BaseUrl+'auth/remove-device/'+id 
    const token = localStorage.getItem ('token') || ''
    return axios ( {
        method : 'delete',
        url,
        headers : {
            Authorization : 'Bearer '+token 
        }
    } )
}

export const deleteAccount = () => {
    const url = BaseUrl + 'auth/delete-account/';
    const token = localStorage.getItem('token') || '';
    return axios({
        method: 'delete',
        url,
        headers: {
            Authorization: 'Bearer ' + token,
        },
    });
};


export const createMultipleEmployees = (employees) => {
    const url = BaseUrl + 'employees-multiple'; 
    const token = localStorage.getItem('token') || '';

    return axios({
        method: 'post',
        url,
        headers: {
            Authorization: 'Bearer ' + token,
        },
        data:  employees 
    });
};
