import { useContext, useEffect, useState } from "react";
import PageHeader from "../Components/Common/PageHeader";
import Layout from "../Components/Layout";
import { getActivity } from "../services/dashboard";
import ReactPlayer from "react-player/youtube";
import { useHistory } from "react-router-dom";
import AddBranch from "../Components/AddBranch";
import Toast from "../Components/Common/Toast";
import Table from "../Components/Common/Table";
import { getSingleDayAttendance } from "../services/attendance";
import DatePicker from "react-datepicker";
import moment from "moment";
import ExportToExcel from "../Components/ExcelSheet";
import { userDetailsContext } from "../context";
import ErrorAlert from "../Components/Common/ErrorAlert";
import Modal from "../Components/Common/Modal";
import Status from "../Components/Common/Status";
import useRedirect from "../Utils/useRedirect";
import { getMockLocation } from "../services/branch";
import PdfExport from "../Components/PdfExport";
import ExportToPDF from "../Components/PdfExport";
//import useRedirect from '../Utils/useRedirect';
const DashBoard = (props) => {
  const history = useHistory();
  //const [activities, setActivities] = useState([]);
  const [isShowBranchModal, setIsShowBranchModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSetupNotCompleted, setSetupCompleted] = useState(-1);
  const [showToast, setShowToast] = useState({});
  const [paginationDetail, setPaginationDetail] = useState({
    itemsPerPage: 10,
    page: 1,
    total: 10,
  });
  const [attendance, setAttendace] = useState([]);
  const [attendanceDate, setAttendanceDate] = useState(new Date());
  const [totalCheckinCount, setTotalCheckins] = useState(0);
  const roleName = useContext(userDetailsContext).role;
  const menu = useContext(userDetailsContext).menu;
  const [errorMessage, setErrorMessage] = useState("");

  const { activitiesresult = [] } = useContext(userDetailsContext);
  //console.log(activitiesresult);

  useRedirect(activitiesresult);

  /* useEffect(() => {
    let step2 = {};
    let step3 = {};

    activitiesresult.forEach((item) => {
      if (item.id === 2) step2 = item;
      if (item.id === 3) step3 = item;
    });

    if (step2.iscompleted === 0) {
      history.push("/newbranch");
    } else if (step3.iscompleted === 0) {
      history.push("/newemployee");
    }
  }, []); */

  const checkInTypes = {
    3: { name: "Direct", value: 1 },
    2: { name: "Employee QR", value: 2 },
    1: { name: "Branch QR", value: 3 },
  };

  const checkInTypesByCode = {
    REMOT: { name: "Direct", value: 1 },
    CARD: { name: "Employee QR", value: 2 },
    FIXED: { name: "Branch QR", value: 3 },
  };

  const hideErrorMessage = () => {
    setErrorMessage("");
  };

  const showListCheckin = (splitCheckin) => {
    if (splitCheckin.length) {
      return (
        <div>
          <ul>
            {splitCheckin.map((sc, index) => {
              return (
                <li key={index}>
                  {sc.checkin +
                    " - " +
                    (sc.checkout ? sc.checkout : "did not checkout")}
                </li>
              );
            })}
          </ul>
        </div>
      );
    }
  };
  

  useEffect(() => {
    if (roleName === "employee") {
      if (menu.includes("checkin")) {
        history.push("/Checkin");
      } else {
        history.push("/Attendance");
      }
    } else {
      const isRestrictPage = localStorage.getItem("isRestrictPage");
      if (isRestrictPage === "true") history.push("/CheckinAdmin");
    }
  }, [roleName]);

  useEffect(() => {
    const selectedDate = moment(attendanceDate).format("YYYY_MM_DD");

    getSingleDayAttendance({
      date: selectedDate,
    })
      .then((results) => {
        const attendanceList = [];
        const {
          result = [],
          itemsPerPage = 10,
          page = 1,
          total = 10,
          totalCheckins,
        } = results.data;

        result.forEach((data) => {
          let totalMinutes = 0;
          let checkTypeName = "";
          let mockLocation = "";
          const splitCheckin = [];

          const checkins = data.attendance.filter(
            (item) => item.checkin_type === 1
          );
          const checkouts = data.attendance.filter(
            (item) => item.checkin_type === 2
          );

          checkins.forEach((checkinItem, index) => {
            const checkoutItem = checkouts[index];
            if (checkoutItem) {
              const entryTime = moment(checkinItem.entry_at);
              const exitTime = moment(checkoutItem.entry_at);
              splitCheckin.push({
                checkin: entryTime.format("HH:mm"),
                checkout: exitTime.isValid()
                  ? exitTime.format("HH:mm")
                  : "did not checkout",
              });

              if (exitTime.isValid()) {
                totalMinutes += exitTime.diff(entryTime, "minutes");
              }
            } else {
              const entryTime = moment(checkinItem.entry_at);
              splitCheckin.push({
                checkin: entryTime.format("HH:mm"),
                checkout: "did not checkout",
              });
            }
          });

          if (data.attendance && data.attendance.length > 0) {
            checkTypeName = data.mock_checkin_type
              ? data.mock_checkin_type
              : checkInTypesByCode[data.attendance[0].checkin_entry_type_code]
                  .name;
          } else {
            checkTypeName = "";
          }

          if (data.attendance && data.attendance.length > 0) {
            mockLocation = data.mock_address ? data.mock_address : "";
          } else {
            mockLocation = "";
          }

          const duration = moment.duration(totalMinutes, "minutes");
          const totalHoursFormatted = `${String(duration.hours()).padStart(
            2,
            "0"
          )}:${String(duration.minutes()).padStart(2, "0")}`;

          //mockLocation = data.mock_location ? data.mock_location : "";

          attendanceList.push({
            ...data,
            name: data.first_name + " " + data.last_name,
            totalHours: totalHoursFormatted,
            checkTypeName,
            mockLocation,
            splitCheckin: showListCheckin(splitCheckin),
          });
        });

        // Set state with attendance data and pagination
        setTotalCheckins(totalCheckins);
        setPaginationDetail({ itemsPerPage, page, total });
        setAttendace(attendanceList);
      })
      .catch(() => {
        setErrorMessage(`Oops! Something went wrong. 
                      Please try again later. If issue persists, double-check input & retry. For help, contact support.`);
      });
  }, [attendanceDate]);

  const onPaginationRoutePage = (page) => {
    const selectedDate = moment(attendanceDate).format("YYYY_MM_DD");
    const updatePagination = { ...paginationDetail, page, date: selectedDate };
    setPaginationDetail(updatePagination);
    getSingleDayAttendance(updatePagination)
      .then((results) => {
        const attendanceList = [];
        const {
          result = [],
          itemsPerPage = 10,
          page = 1,
          total = 10,
        } = results.data;
        result.forEach((data) => {
          let totalMinutes = 0;
          let checkTypeName = "";
          let mockLocation = "";
          const splitCheckin = [];

          const checkins = data.attendance.filter(
            (item) => item.checkin_type === 1
          );
          const checkouts = data.attendance.filter(
            (item) => item.checkin_type === 2
          );

          checkins.forEach((checkinItem, index) => {
            const checkoutItem = checkouts[index];
            if (checkoutItem) {
              const entryTime = moment(checkinItem.entry_at);
              const exitTime = moment(checkoutItem.entry_at);
              splitCheckin.push({
                checkin: entryTime.format("HH:mm"),
                checkout: exitTime.isValid()
                  ? exitTime.format("HH:mm")
                  : "did not checkout",
              });

              if (exitTime.isValid()) {
                totalMinutes += exitTime.diff(entryTime, "minutes");
              }
            } else {
              const entryTime = moment(checkinItem.entry_at);
              splitCheckin.push({
                checkin: entryTime.format("HH:mm"),
                checkout: "did not checkout",
              });
            }
          });

          if (data.attendance && data.attendance.length > 0) {
            checkTypeName = data.mock_checkin_type
              ? data.mock_checkin_type
              : checkInTypesByCode[data.attendance[0].checkin_entry_type_code]
                  .name;
          } else {
            checkTypeName = "";
          }

          if (data.attendance && data.attendance.length > 0) {
            mockLocation = data.mock_address ? data.mock_address : "";
          } else {
            mockLocation = "";
          }

          const duration = moment.duration(totalMinutes, "minutes");
          const totalHoursFormatted = `${String(duration.hours()).padStart(
            2,
            "0"
          )}:${String(duration.minutes()).padStart(2, "0")}`;

          //mockLocation = data.mock_location ? data.mock_location : "";

          attendanceList.push({
            ...data,
            name: data.first_name + " " + data.last_name,
            totalHours: totalHoursFormatted,
            checkTypeName,
            mockLocation,
            splitCheckin: showListCheckin(splitCheckin)
          });
        });

        setPaginationDetail({ itemsPerPage, page, total });
        setAttendace(attendanceList);
      })
      .catch(() => {
        setErrorMessage(`Oops! Something went wrong. 
                Please try again later. If issue persists, double-check input & retry. For help, contact support.`);
      });
  };

  const onChangeRowsPerPage = (itemsPerPage) => {
    const selectedDate = moment(attendanceDate).format("YYYY_MM_DD");
    const updatePagination = {
      ...paginationDetail,
      itemsPerPage,
      page: 1,
      date: selectedDate,
    };
    setPaginationDetail(updatePagination);
    getSingleDayAttendance(updatePagination)
      .then((results) => {
        const attendanceList = [];
        const {
          result = [],
          itemsPerPage = 10,
          page = 1,
          total = 10,
        } = results.data;
        result.forEach((data) => {
          let totalMinutes = 0;
          let checkTypeName = "";
          let mockLocation = "";
          const splitCheckin = [];

          const checkins = data.attendance.filter(
            (item) => item.checkin_type === 1
          );
          const checkouts = data.attendance.filter(
            (item) => item.checkin_type === 2
          );

          checkins.forEach((checkinItem, index) => {
            const checkoutItem = checkouts[index];
            if (checkoutItem) {
              const entryTime = moment(checkinItem.entry_at);
              const exitTime = moment(checkoutItem.entry_at);
              splitCheckin.push({
                checkin: entryTime.format("HH:mm"),
                checkout: exitTime.isValid()
                  ? exitTime.format("HH:mm")
                  : "did not checkout",
              });

              if (exitTime.isValid()) {
                totalMinutes += exitTime.diff(entryTime, "minutes");
              }
            } else {
              const entryTime = moment(checkinItem.entry_at);
              splitCheckin.push({
                checkin: entryTime.format("HH:mm"),
                checkout: "did not checkout",
              });
            }
          });

          if (data.attendance && data.attendance.length > 0) {
            checkTypeName = data.mock_checkin_type
              ? data.mock_checkin_type
              : checkInTypesByCode[data.attendance[0].checkin_entry_type_code]
                  .name;
          } else {
            checkTypeName = "";
          }

          if (data.attendance && data.attendance.length > 0) {
            mockLocation = data.mock_address ? data.mock_address : "";
          } else {
            mockLocation = "";
          }

          const duration = moment.duration(totalMinutes, "minutes");
          const totalHoursFormatted = `${String(duration.hours()).padStart(
            2,
            "0"
          )}:${String(duration.minutes()).padStart(2, "0")}`;

          //mockLocation = data.mock_location ? data.mock_location : "";

          attendanceList.push({
            ...data,
            name: data.first_name + " " + data.last_name,
            totalHours: totalHoursFormatted,
            checkTypeName,
            mockLocation,
            splitCheckin: showListCheckin(splitCheckin)
          });
        });

        setPaginationDetail({ itemsPerPage, page, total });
        setAttendace(attendanceList);
      })
      .catch(() => {
        setErrorMessage(`Oops! Something went wrong. 
                Please try again later. If issue persists, double-check input & retry. For help, contact support.`);
      });
  };

  // Helper function to get the first checkin time
  const getFirstCheckin = (checkins) => {
    if (checkins && checkins.length > 0) {
      const firstCheckin = moment(checkins[0].entry_at);
      return firstCheckin.format("HH:mm");
    }
    return "";
  };

  // Helper function to get the last checkout time
  const getLastCheckout = (checkins, checkouts) => {
    if (checkins && checkins.length > 0 && checkouts && checkouts.length > 0) {
      const lastCheckoutItem = checkouts[checkouts.length - 1];
      const exitTime = moment(lastCheckoutItem.entry_at);
      if (exitTime.isValid()) {
        return exitTime.format("HH:mm");
      }
    }
    return "did not checkout";
  };

  const closeBranchModal = () => {
    setIsShowBranchModal(false);
    setIsLoading(false);
  };

  const addBranchHandler = (data) => {
    setIsShowBranchModal(false);
    setIsLoading(false);
    setShowToast({ isShow: true, message: "Created Branch Successfully!" });
    setTimeout(() => {
      setShowToast({ isShow: false, message: "" });
    }, 2000);
  };

  const onClickTodo = (e, activityName) => {
    e.preventDefault();
    if (activityName === "Add new branch") {
      setIsShowBranchModal(true);
    } else {
      history.push("/AddEmployee");
    }
  };

  const closeToastHandler = () => {
    setShowToast({ isShow: false, message: "" });
  };

  const header = [
    { key: "id", label: "Id" },
    { key: "name", label: "Name" },
    { key: "splitCheckin", label: "Check-in/Check-out" },
    { key: "totalHours", label: "Total Hours" },
    { key: "checkTypeName", label: "Checkin Type" },
    { key: "mockLocation", label: "Location" },
  ];

  const onChangeDate = (date) => {
    setAttendanceDate(date);
  };
  
  

  return (
    <>
      <Layout>
        <PageHeader title="Dashboard"></PageHeader>
        {!!errorMessage && (
          <ErrorAlert close={hideErrorMessage}>{errorMessage}</ErrorAlert>
        )}

        <>
          <div className="container row">
            <div className="col dashboard-tile-wrap">
              <div className="dashboard-tiles">
                <p>Total</p>
                <strong>{attendance.length}</strong>
              </div>
            </div>
            <div className="col dashboard-tile-wrap">
              <div className="dashboard-tiles">
                <p>Checkins</p>
                <strong>{totalCheckinCount}</strong>
              </div>
            </div>
            <div className="col dashboard-tile-wrap dashboard-date-wrap">
              <div className="dashboard-tiles">
                <span>Select Date</span>
                <DatePicker
                  maxDate={moment().toDate()}
                  id="startDate"
                  className="dashboard-date-picker"
                  selected={attendanceDate}
                  onChange={onChangeDate}
                />
                
                
              </div>
            </div>
             <div className="col dashboard-tile-wrap dashboard-date-wrap">
              <div className="dashboard-tiles">
              {!!attendance.length && (
                  <ExportToExcel
                    label="Export Excel"
                    excelData={attendance.map((employee) => {
                      // Step 1: Create an array to store check-in/check-out pairs
                      const checkinTimes = [];
                    
                      // Step 2: Loop through each attendance record
                      employee.attendance.forEach((curr) => {
                        const { checkin_type, entry_at } = curr; // Destructure the necessary properties
                        const formattedTime = moment(entry_at).format("HH:mm"); // Format the time
                    
                        if (checkin_type === 1) {
                          // Step 3: If it's a check-in (checkin_type 1), push a new check-in record with null checkoutTime
                          checkinTimes.push({ checkinTime: formattedTime, checkoutTime: null });
                        } else if (checkin_type === 2) {
                          // Step 4: If it's a check-out (checkin_type 2), find the last check-in with null checkoutTime and assign the checkout time
                          const lastCheckin = checkinTimes.find(item => item.checkoutTime === null);
                          if (lastCheckin) {
                            lastCheckin.checkoutTime = formattedTime;
                          }
                        }
                      });
                    
                      const formattedCheckins = checkinTimes
                      .map(pair => {
                        if (pair.checkoutTime) {
                          return `${pair.checkinTime} - ${pair.checkoutTime}`; // If there's a check-out, format as "checkinTime - checkoutTime"
                        } else {
                          return `${pair.checkinTime} - did not checkout`; // If there's no check-out, show "did not checkout"
                        }
                      })
                      .join(', '); // Join the pairs into a single string, separated by commas
                    
                      // Step 6: Return the employee data with the formatted check-in/check-out data
                      return {
                        ...employee, // Keep the original employee data
                        date: moment(attendanceDate).format("DD-MM-YYYY"), // Add the date in the desired format
                        splitCheckin: formattedCheckins , // Add the formatted check-in/check-out times or 'No check-ins' if none
                      };
                    })}
                    
                    fileName={"Attendance_"}
                    exportKeys={[
                      { key: "date", label: "Date" },
                      { key: "id", label: "Id" },
                      { key: "name", label: "Name" },
                      { key: "splitCheckin",label:"Checkin/Checkout" },
                      { key: "checkTypeName" ,label:"CheckinType" },
                      { key: "totalHours" },
                      { key: "mockLocation", label: "Location" },
                    ]}
                  />
                )} <br/>
              {!!attendance.length && (

                  <PdfExport
                    label="Export Pdf"
                    className="mt-10"
                    pdfData={attendance.map((employee) => {
                      const checkinTimes = [];
                  
                      employee.attendance.forEach((curr) => {
                        const { checkin_type, entry_at } = curr;
                        const formattedTime = moment(entry_at).format("HH:mm");
                  
                        if (checkin_type === 1) {
                          checkinTimes.push({ checkinTime: formattedTime, checkoutTime: null });
                        } else if (checkin_type === 2) {
                          const lastCheckin = checkinTimes.find(item => item.checkoutTime === null);
                          if (lastCheckin) {
                            lastCheckin.checkoutTime = formattedTime;
                          }
                        }
                      });
                  
                      const formattedCheckins = checkinTimes
                        .map(pair => {
                          if (pair.checkoutTime) {
                            return `${pair.checkinTime} - ${pair.checkoutTime}`;
                          } else {
                            return `${pair.checkinTime} - did not checkout`;
                          }
                        })
                        .join(', ');
                  
                      return {
                        ...employee,
                        date: moment(attendanceDate).format("DD-MM-YYYY"),
                        splitCheckin: formattedCheckins
                      };
                    })}
                  
                    
                    fileName={"Attendance_"}
                    exportKeys={[
                      { key: "date", label: "Date" },
                      { key: "id", label: "Id" },
                      { key: "name", label: "Name" },
                      { key: "splitCheckin",label:"Checkin/Checkout" },
                      { key: "checkTypeName" ,label:"CheckinType" },
                      { key: "totalHours" },
                      { key: "mockLocation", label: "Location" },
                    ]}
                  />
                
                  )}
              </div>
            </div> 
          </div>
          <Table
            isLoader={isLoading}
            header={header}
            body={attendance}
            paginationDetail={paginationDetail}
            routePage={onPaginationRoutePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
          />
        </>
        {showToast.isShow && (
          <Toast message={showToast.message} closeToast={closeToastHandler} />
        )}
        {isShowBranchModal && (
          <AddBranch
            closeModal={closeBranchModal}
            addBranch={addBranchHandler}
          />
        )}
      </Layout>
    </>
  );
};

export default DashBoard;
