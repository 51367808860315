import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const useRedirect = (activities) => {
  const history = useHistory();

  useEffect(() => {
    let step2={};
    let step3={};
    activities.forEach(item => {
      if (item.id === 2) step2 = item;
      if (item.id === 3) step3 = item;
    });

    if (step2.iscompleted === 0) {
      history.push('/newbranch');
    } else if (step3.iscompleted === 0) {
      history.push('/newemployee');
    }
  }, [activities, history]); 
};

export default useRedirect;