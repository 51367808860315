import Button from "../Common/Button";
import { mobileMenuHandler } from "../../context";
import { useContext } from "react";
import BackButton from "../BackButton";

const PageHeader = (props) => {
  const { title, label, onClick,showBackButton=true } = props;
  const { showNavBar = false } = useContext(mobileMenuHandler) || {};

  return (
    <>
      <div
  className={`d-flex align-items-center px-3 navbar border-bottom ${
    label ? "justify-content-between" : "justify-content-start"
  }`}
  style={{ flexWrap: 'nowrap' }}>
        <div className="d-flex align-items-center mr-auto ">
          {showNavBar && (
            <i
              class="bi bi-list show-nav-bar-icon d-lg-none"
              onClick={showNavBar}
            ></i>
          )}
          <span className="page-title">{title}</span>
        </div>

        <div className="d-flex align-items-center ml-auto ">
          {label && (
            <Button
              onClick={onClick}
              className="btn-primary f-s14 f-w-600 btn-sm"
            >
              {label}
            </Button>
          )}
          {showBackButton && <BackButton />}
        </div>
      </div>
    </>
  );
};

export default PageHeader;
