import axios from "axios"
const BaseUrl = process.env.REACT_APP_BASEURL;

export const getBranch = (params) => {
   const url = BaseUrl+'workspaces' 
   const token = localStorage.getItem ('token') || ''
   return axios ( {
       method : 'get',
       url,
       headers : {
           Authorization : 'Bearer '+token 
       },
       params

   } )
}

export const createBranch = (data) => {
    const url = BaseUrl+'workspaces' 
    const token = localStorage.getItem ('token') || '';
    return axios( {
        method : 'post',
        url,
        headers : {
            Authorization : 'Bearer '+token
        },
        data
    })
}
export const updateBranch = (data) => {
    const url = BaseUrl+'workspaces/' + data.id
    const token = localStorage.getItem ('token') || ''
    return axios( {
        method : 'put',
        url,
        headers : {
            Authorization : 'Bearer '+token
        },
        data
    })
}
export const deleteBranch = (id) => {
    const url = BaseUrl+'workspaces/' + id ;
    const token = localStorage.getItem ('token') || '' ;
    return axios( {
        method : 'delete',
        url,
        headers : {
            Authorization : 'Bearer '+token
        },
    })
}

export const createMockLocation = (mocklocation) => {
    const url = BaseUrl+'auth/mock-details' 
    const token = localStorage.getItem ('token') || '';
    return axios( {
        method : 'post',
        url,
        headers : {
            Authorization : 'Bearer '+token
        },
        data:mocklocation
    })
}

export const getMockLocation = (params) => {
    const url = BaseUrl+'auth/mock-details' 
    const token = localStorage.getItem ('token') || ''
    return axios ( {
        method : 'get',
        url,
        headers : {
            Authorization : 'Bearer '+token 
        },
        params
 
    } )
 }
