import { useEffect, useState } from 'react';
import {useHistory} from 'react-router-dom';
//components
import BackgroundEntryPage from '../Components/BackgroundEntryPage.js';
import FormHeader from '../Components/Common/FormHeader';
import Button from '../Components/Common/Button';
import ErrorMessage from '../Components/Common/ErrorMessage.js';
import Form from '../Components/Common/Form.js';
//utils
import { isUserNameValid as getIsUserNameValid} from '../Utils/isUserNameValid';
import { validation } from '../Utils/validation.js';
import {isPasswordValid} from '../Utils/IsPasswordValid';
import { signupError } from '../constant/errorMessage'
//services
import { userNameValidate, getCountries, signup as signupService } from '../services/auth';
import moment from 'moment';


const Signup=()=>{

    


    const router = useHistory();
    const [primaryInfo, setPrimaryInfo] = useState({});
    const [secondaryInfo, setSecondaryInfo] = useState({});
    const [subState, setSubState] = useState({});
    const [errorMessage, setErrorMessage] = useState({});
    const [isUserNameValid, setIsUserNameValid ] = useState(false);
    const [isShowSeconadaryForm, setIsShowSecondaryForm] = useState(false);
    const [page , setPage] = useState(1);
    const [isLoading, setLoading] = useState(false);
    const [ isEnterId, setEnterId ] = useState(false);


    



    const onClickEyeIcon = (e) => {
        const newPrimaryFormFields = [...primaryFormFieldsState];
        const updatedFormField = newPrimaryFormFields.map(({...formField})=>{
            if(formField.row && formField.row[0] && formField.row[0].key === 'password') {
                if(formField.row[0].type === "password") {
                    formField.row[0].type = "text";
                    formField.row[0].icon = <i className="bi bi-eye-slash-fill"></i>;
                }
                else {
                    formField.row[0].type = "password";
                    formField.row[0].icon = <i className="bi bi-eye-fill"></i>;
                }
                
            }
            return formField;
        })
        setPrimaryFormFields(updatedFormField)
    }
    
    const primaryFormFields = [
        //{ row : [ { key : 'firstName', type : 'text', validationType : 'lettersOnly', maxLength : 30 }, { key:'lastName', type : 'text', validate : 'lettersOnly', maxLength : 30 } ] },
        { row : [ { key : 'email', type : 'email', validationType : 'email' } ]},
        { row : [ { key : 'password', type : 'password', icon: <i className="bi bi-eye-fill"></i>, onCLickIconHandler: onClickEyeIcon, isIConRight:true} ] },
        { row : [ { key : 'companyName', type : 'text', isMandatory : false, validationType : 'lettersOnly', maxLength : 30, emptyCheck : false }] },
        //{ row : [{ key : 'mobileNumber', type: 'text', validationType : 'mobileNumber', emptyCheck : false, icon : secondaryInfo.country ? secondaryInfo.country.phone_code : '' , isMandatory : false}]}
        //{ row : [ { key : 'organizationId', label: 'Create Unique ID for your Company', type : 'text', className : isUserNameValid ? 'valid-input' : '' ,helpText: 'numbers, lowercase and  _  only allowed' } ] }
    ];

    const [primaryFormFieldsState, setPrimaryFormFields] = useState(primaryFormFields);

    useEffect(()=>{
        getCountries().then((results)=>{
            if(results && results.data && results.data.length > 0 ) {
               const countryField = { row : [{key : 'country', type : 'autocomplete', options: results.data, isMandatory : false, emptyCheck : false, validationType : 'autocomplete'}]};
               setPrimaryFormFields([...primaryFormFields, countryField]);
            }
        }).catch((error)=>{
            if(error && error.response) {
                //errorMessage.organizationId = error.response.data.error;
               // setErrorState ()
            }
        }); 
    }, []);

    const secondaryFormFields = [
        // { row : [ { key : 'selectType', type : 'select', options : [{ name: "Select Type", value: "" }, { name: "School", value: 1 }, { name: "Industry", value: 2 }, { name: "Event", value: 3 }], key : 'selectType' } ] },
        { row : [ { key : 'companyName', type : 'text', isMandatory : false, validationType : 'lettersOnly', maxLength : 30 }] },
       // { row : [{key : 'country', type : 'autocomplete', options: countriesList, emptyCheck : true, validationType : 'autocomplete'}]},

        { row : [{ key : 'mobileNumber', type: 'text', validationType : 'mobileNumber', emptyCheck : false, icon : secondaryInfo.country ? secondaryInfo.country.phone_code : '' , isMandatory : false}]}
    ];

    const onChangePrimaryInfo = (event, key)=>{
        const newPrimaryInfo = { ...primaryInfo}; 
        const newErrorMessage = {...errorMessage};
        newPrimaryInfo[key] = event.target.value;
        newErrorMessage[key] = '';
        // if(key == 'organizationId'){
        //     setEnterId(!isEnterId);
        // }
        setPrimaryInfo({...newPrimaryInfo});
        setErrorMessage({ ...newErrorMessage});
    };

    // useEffect(() => {
    //     const isUserNameValid = getIsUserNameValid(primaryInfo['organizationId']);
    //     if(isUserNameValid) {
    //         const userNameValidLength = primaryInfo['organizationId'] && primaryInfo['organizationId'].length > 5;
    //         if(userNameValidLength) {
    //             const timeOutId = setTimeout( validateOrganizationId, 600);
    //             return () => clearTimeout(timeOutId);
    //         }
    //     }
    // }, [isEnterId]);


    const validateOrganizationId = () => {
        const newErrorMessage = {...errorMessage};
        const isUserNameValid = getIsUserNameValid(primaryInfo['organizationId'])
        const userNameValidLength = primaryInfo['organizationId'].length > 5;
        if(primaryInfo['organizationId'] && isUserNameValid && userNameValidLength){
            userNameValidate(primaryInfo['organizationId']).then((result)=>{
                setIsUserNameValid(true);
                newErrorMessage['organizationId'] = '';
                setErrorMessage({...newErrorMessage});
                
            }).catch(()=>{
                setIsUserNameValid(false);
                newErrorMessage['organizationId'] = 'Organization Id already exits';
                setErrorMessage({...newErrorMessage});
            })
        }
        else if(primaryInfo['organizationId']){
            setIsUserNameValid(false);
            newErrorMessage['organizationId'] = !isUserNameValid ? 'Organization Id not correct format, It should be numbers, lowercase and  _  only allowed' : !userNameValidLength ? "Organization Id length should be more than 5" : "";
        }
        
        setErrorMessage({...newErrorMessage});
    }
    
    
    const onChangeSecondaryInfo = (event, key)=>{
        const newSecondaryInfo = {...secondaryInfo};
        const newErrorMessage = {...errorMessage};
        newSecondaryInfo[key] = event.target.value;
        newErrorMessage[key] = '';
        setSecondaryInfo({...newSecondaryInfo});
        setErrorMessage({...newErrorMessage});
    };

    const autoCompleteOnSelect = (value, action, key)=>{

        const newPrimaryInfo = { ...primaryInfo}; 
        const newErrorMessage = {...errorMessage};
        newPrimaryInfo[key] = {...value};
        newErrorMessage[key] = '';
        // if(key == 'organizationId'){
        //     setEnterId(!isEnterId);
        // }
        setPrimaryInfo({...newPrimaryInfo});
        setErrorMessage({ ...newErrorMessage});
    };
    
    const onBlurHandler =(event, key)=>{
        if(key == 'organizationId'){
            const newErrorMessage = {...errorMessage};
            if(primaryInfo[key] && getIsUserNameValid(primaryInfo[key]) && primaryInfo[key].length > 5){
                userNameValidate(primaryInfo[key]).then((result)=>{
                    setIsUserNameValid(true);
                    newErrorMessage[key] = '';
                    setErrorMessage({...newErrorMessage});
                    
                }).catch(()=>{
                    setIsUserNameValid(false);
                    newErrorMessage[key] = 'Organization Id already exits';
                    setErrorMessage({...newErrorMessage});
                })
            }
            else if(primaryInfo[key]){
                setIsUserNameValid(false);
                newErrorMessage[key] = 'Organization Id not correct format';
            }
            
            setErrorMessage({...newErrorMessage});
        }
    };

    const validatePrimaryInfo = ()=>{
        const validationResult = validation(primaryFormFields, primaryInfo);
        const newErrorMessage = validationResult.errorMessage;
        let isValid = validationResult.isValid;
        if(!isPasswordValid(primaryInfo.password)){
            newErrorMessage.password = signupError.password;
            isValid = false;
        }
        return{errorMessage : validationResult.errorMessage, isValid : isValid};
    };

    const validateSecondaryInfo = ()=>{ 
        const validationResult = validation(secondaryFormFields, secondaryInfo);
        setErrorMessage({...validationResult.errorMessage});
        return{isValid : validationResult.isValid};
    };

    const completePrimaryInfo = ()=>{
        const validationResult = validatePrimaryInfo();
        const newErrorMessage = {...validationResult.errorMessage};
        const showSecondaryForm = validationResult.isValid;
        setErrorMessage({...newErrorMessage});
        if(showSecondaryForm){
            setIsShowSecondaryForm(true);
        }
    };

    const showPrimaryForm = ()=>{
        setIsShowSecondaryForm(false);
    };

    const completeProfile = ()=>{

        const validationResult = validatePrimaryInfo();
        const newErrorMessage = {...validationResult.errorMessage};
        const isCreateAccount = validationResult.isValid;
        setErrorMessage({...newErrorMessage});
        if(isCreateAccount){
            const dateObject = new Date().getTimezoneOffset();

            let utc = -Math.round(dateObject/60)+':'+-(dateObject%60);
            utc = utc < 0 ?utc : '+'+utc;

            const { lastName, companyName, email, password, country} = primaryInfo;
            const data = {
                first_name : companyName || 'Super',
                last_name : "Admin",
                mail_id : email,
                //organization_name : organizationId,
                password : password,
                country_id : country && country.id ? country.id : 1,
                attendance_type_id : 2,
                //user_name : organizationId,
                timezone : moment().format('Z')
            };
            setLoading(true);
            signupService(data).then((result)=>{
                //setLoading(false);
                sessionStorage.setItem('email', primaryInfo.email);
                router.push('./SignupOtpVerify');
            }).catch((err)=>{
                setLoading(false);
                if(err.response) {
                    newErrorMessage.common = err.response.data.data;
                }
                else {
                    newErrorMessage.common = "There is an error, please try with valid input or try later";
                }
                setErrorMessage({...newErrorMessage});
            })

        }
        
    };

    return(
        <BackgroundEntryPage>
            <form autoComplete='off'>
            <FormHeader primaryHeading="Start tracking" subHeading="Create your company account" />
            { errorMessage.common  &&
            <ErrorMessage label = {errorMessage.common}/>
            }
            
                {!isShowSeconadaryForm &&
                    <>
                        <Form rows = {primaryFormFieldsState} value = {primaryInfo} errorMessage = {errorMessage} onChange = {onChangePrimaryInfo}
                        autoCompleteOnSelect = {autoCompleteOnSelect} />
                        <Button className = 'btn-primary w-100 btn-sm mt-10' onClick = {completeProfile}>{"Sign up"}</Button>
                    </>
                }
                {isShowSeconadaryForm &&
                    <>
                        {/* <div className =  'f-s16 f-w-600'  title = 'Back' ><i className="bi bi-arrow-left"></i><span className = 'text-muted px-2 f-s16 '>Back</span></div>  */}
                        <Button className = 'p-0' onClick = {showPrimaryForm}>
                            <div className = 'd-flex align-items-center'>
                                <i className="bi bi-arrow-left large-icon"></i>
                            </div>
                            
                        </Button>
                        <Form rows = {secondaryFormFields} value = {secondaryInfo} errorMessage = {errorMessage} onChange = {onChangeSecondaryInfo}  autoCompleteOnSelect = {autoCompleteOnSelect}/>
                        <Button className = 'btn-primary w-100 btn-sm' onClick = {completeProfile} disabled={isLoading}>{isLoading ? "Loading..." : "Complete"}</Button>
                    </>
                }
            
            {!isShowSeconadaryForm && 
            <>
                <div className="line mt-2 mb-4 ">
                    <h6 className="border-bottom f-s15"><span>or</span></h6>
                </div>
                <div className="text-center">
                    <Button className = "btn-outline-primary btn-sm" onClick = {() => { router.push('/Login') }}>Sign In</Button>
                </div>
            </>
            }

            </form>
        </BackgroundEntryPage>
    )
}
export default Signup