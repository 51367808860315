import { useRef, useEffect } from "react";
import FormLabel from "./FormLabel";
import ErrorMessage from "./ErrorMessage";

const AddressInput = ({ address, onChange, error }) => {
  const autocompleteRef = useRef(null);

  useEffect(() => {
    const googleMapScript = document.createElement("script");
    googleMapScript.src =
      "https://maps.googleapis.com/maps/api/js?sensor=false&libraries=places&key=AIzaSyDOmj5IUQnHCwXYlkkaeEahGpqZiRWc0iw";
    googleMapScript.async = true;
    document.body.appendChild(googleMapScript);

    googleMapScript.addEventListener("load", () => {
      const google = window.google;
      const autocomplete = new google.maps.places.Autocomplete(autocompleteRef.current);
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (place.geometry) {
          onChange(place.formatted_address, place.geometry.location.lat(), place.geometry.location.lng());
        }
      });
    });

    return () => {
      document.body.removeChild(googleMapScript); 
    };
  }, []);

  return (
    <div>
      <input
        ref={autocompleteRef}
        className="input-controls"
        type="text"
        placeholder="Enter location"
        value={address}
        onChange={(e) => onChange(e.target.value)}
      />
      <ErrorMessage label={error} />
    </div>
  );
};

export default AddressInput;
