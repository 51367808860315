import { useState, useEffect, useContext } from "react";
//import {useRouter} from 'next/router' ;
//componetns
import Layout from "../Components/Layout";
import ShowDetails from "../Components/Common/ShowDetails";
import ShowImage from "../Components/Common/ShowImage";
import PageHeader from "../Components/Common/PageHeader";
import Form from "../Components/Common/Form";
import Tap from "../Components/Common/Tap";
import Button from "../Components/Common/Button";
import Select from "../Components/Common/Select";
import ErrorMessage from "../Components/Common/ErrorMessage";
import { userDetailsContext } from "../context";
//utils
import { isUserNameValid as getIsUserNameValid } from "../Utils/isUserNameValid";
import { splitPascalCase } from "../Utils/splitPascalCase";
import { isPasswordValid } from "../Utils/IsPasswordValid";
import { loginError } from "../constant/errorMessage";
import timezoneList from "../Utils/timezone";
import { createMockLocation, getMockLocation } from "../services/branch";
//services
import {
  getUserDetails,
  updatePassword,
  updateTimezone,
  updateUsername,
} from "../services/settings";
import Input from "../Components/Common/Input";
import { userNameValidate } from "../services/auth";
import ErrorAlert from "../Components/Common/ErrorAlert";
import Modal from "../Components/Common/Modal";
import Status from "../Components/Common/Status";
import useRedirect from "../Utils/useRedirect";
import AddressInput from "../Components/Common/AddressInput";
import Toast from "../Components/Common/Toast";
import moment from "moment-timezone";
const Row = (props) => {
  return <div className="mt-3">{props.children}</div>;
};

const Settings = (props) => {
  const { activitiesresult = [] } = useContext(userDetailsContext);

  useRedirect(activitiesresult);

  //const router = useRouter();
  // state for change tap
  const [activeTap, setActiveTap] = useState("companyInfo");
  // state for change password
  const [passwords, setPasswords] = useState({});
  // state for error message password
  const [passwordError, setPasswordError] = useState({});

  const [userDetail, setUserDetail] = useState({ customCompanyName: "" });

  const [isLoader, setLoader] = useState(false);
  const [isLoaderCompanyName, setLoaderCompanyName] = useState(false);
  const [companyIdValid, setCompanyIdValid] = useState(false);
  // state for set status
  const [status, setStatus] = useState({
    showStatus: true,
    spinner: true,
    success: false,
    successMessage: "",
  });
  const [updatePasswordRequest, setUpdatePasswordRequest] = useState(0);

  const [updateTimezoneStatus, setupdateTimezoneStatus] = useState(0);
  const [updateCompanyName, setUpdateCompanyName] = useState(0);

  const [errorCustomCompanyId, setErrorCustomCompanyId] = useState("");

  const roleName = useContext(userDetailsContext).role;

  const [serverErrorMessage, setServerErrorMessage] = useState("");
  const [advSetting, setAdvSetting] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [checkinType, setCheckinType] = useState("");
  const [showToast, setShowToast] = useState({});
  const [errorMessage, setErrorMessage] = useState({});
  const [disable, setDisable] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [timezoneList, setTimezoneList] = useState([]);

  const passwordChangeHandler = (event, key) => {
    setPasswords({ ...passwords, [key]: event.target.value });
    setPasswordError({ ...passwordError, [key]: "" });
  };
  const orgId = useContext(userDetailsContext).organizationId;
  const isOrganizationAllowed = orgId === "CKN1739192128490" || orgId === "CKN1739086839231" ;
// || "omega1234";
  useEffect(() => {
    const timezones = moment.tz.names().map((timezone) => {
      const offset = moment.tz(timezone).format("Z");
      return {
        offset: `GMT${offset}`,
        name: `${timezone} ${offset}`,
        label: timezone,
        value: offset,
      };
    });
    setTimezoneList(timezones);
  }, []);

  useEffect(() => {
    setLoader(true);

    getMockLocation() 
      .then((results) => {
        const { result = [] } = results.data;



        if (result.length > 0) {
          const { mock_address, mock_checkin_type, latitude, longitude } = result[0];


          setAdvSetting(mock_address || ''); 
          setCheckinType(mock_checkin_type || ''); 
          setLatitude(latitude || ''); 
          setLongitude(longitude || ''); 
        } else {
          setServerErrorMessage('No mock location data available.');
        }
        
        setLoader(false);
      })
      .catch(() => {
        setServerErrorMessage('Oops! Something went wrong fetching the mock location.');
        setLoader(false);
      });
  }, []);
  useEffect(() => {
    //setLoader(true);
    getUserDetails()
      .then((results) => {
        const response = results.data;
        const data = {
          name: `${response.first_name} ${response.last_name}`,
          timezone: response.timezone,
          mailId: response.mail_id,
          companyId: response.company_id,
          customName: response.custom_name,
          customCompanyName: "",
          isAllowRemark: response.is_allow_remark,
        };
        setStatus({ ...status, showStatus: false });
        setUserDetail(data);
      })
      .catch((err) => {
        //setLoader(false);
        setStatus({ ...status, showStatus: false });
        setServerErrorMessage(`Oops! Something went wrong. 
                Please try again later. If issue persists, double-check input & retry. For help, contact support.`);
      });
  }, [true]);

 

  const savePassword = () => {
    const errorMessage = {};

    if (passwordFields.length) {
      // flag for after check all input fields not empty to continue to check new and confirm password match
      let matchNewConfirmPassword = true;
      // flag for after check all input fields not empty and check new and confirm password match to continue to check new and confirm password match password requirement
      let validPassword = true;

      passwordFields.map((fields) => {
        fields.row.map((col) => {
          // empty check
          if (!passwords[col.key]) {
            errorMessage[col.key] =
              splitPascalCase(col.key) + " field should be empty";
            matchNewConfirmPassword = false;
            validPassword = false;
          }
          // new and confirm password check
          else if (
            matchNewConfirmPassword &&
            passwords.newPassword != passwords.confirmPassword
          ) {
            validPassword = false;
            errorMessage.confirmPassword = "new and confirm password not match";
          }
          // to check password match password requirement
          else if (
            validPassword &&
            col.validate == "password" &&
            !isPasswordValid(passwords[col.key])
          ) {
            errorMessage.confirmPassword = loginError.password;
            validPassword = false;
          }
        });
      });

      if (validPassword && matchNewConfirmPassword) {
        const data = {
          old_password: passwords.currentPassword,
          new_password: passwords.newPassword,
        };
        setLoader(true);
        updatePassword(data)
          .then((results) => {
            setUpdatePasswordRequest(1);
            setLoader(false);
          })
          .catch(() => {
            setUpdatePasswordRequest(2);
            setLoader(false);
            setServerErrorMessage(`Oops! Something went wrong. 
                Please try again later. If issue persists, double-check input & retry. For help, contact support.`);
          });
      } else {
        setPasswordError({ ...errorMessage });
      }
    }
  };

  const basicInformation = {
    col1: [
      {
        row: [{ key: "name" }],
      },
      {
        row: [{ key: "mailId" }],
      },
      {
        row: [{ key: "companyId" }],
      },
    ],
  };
  const passwordFields = [
    {
      row: [{ type: "password", key: "currentPassword", validate: "password" }],
    },
    {
      row: [{ type: "password", key: "newPassword", validate: "password" }],
    },
    {
      row: [{ type: "password", key: "confirmPassword", validate: "password" }],
    },
  ];

  const subscriptionPlans = [
    {
      planName: "Basic",
      planPrice: "$300",
      subText: "monthly",
      planFeature: ["fdewq", "wdwfwff"],
      activated: "Currenly Activated",
    },
    {
      planName: "Middle",
      planPrice: "$300",
      subText: "monthly",
      planFeature: ["fdewq", "wdwfwff"],
      button: "Upgrad Plan",
    },
    {
      planName: "Primium",
      planPrice: "$300",
      subText: "monthly",
      planFeature: ["fdewq", "wdwfwff", "wdwfwff", "wdwfwff"],
      button: "Upgrad Plan",
    },
  ];

  const changeTap = (tabName) => {
    setActiveTap(tabName);
  };

  const onChangeTimezone = (e) => {
    const selectedTimezoneOffset = e.target.value;
    const updateUser = { ...userDetail };
    updateUser.timezone = selectedTimezoneOffset;
    setUserDetail(updateUser);
  };

  const onChangeAdvSetting = (address, lat, lon) => {
    setAdvSetting(address);
    setLatitude(lat);
    setLongitude(lon);
  };

  const updateTimezoneHandler = async () => {
    try {
      setLoader(true);
      const updatedResult = await updateTimezone({
        timezone: userDetail.timezone,
      });
      if (updatedResult.data.status) {
        setLoader(false);
        setupdateTimezoneStatus(1);
      } else throw new Error("something went wrong! try again");
    } catch (error) {
      setLoader(false);
      setupdateTimezoneStatus(2);
      setServerErrorMessage(`Oops! Something went wrong. 
                Please try again later. If issue persists, double-check input & retry. For help, contact support.`);
    }
  };

  const tapMenu = [
    { key: "companyInfo", label: "User Info", icon: "bi bi-person-circle" },
    { key: "password", icon: "bi bi-key-fill" },
    ...(isOrganizationAllowed
      ? [{ key: "advancedSettings", label: "Advanced Settings", icon: "bi bi-key-fill" }]
      : [])
  ];

  const onChangeCustomId = (e) => {
    const updateUser = { ...userDetail };
    updateUser.customCompanyName = e.target.value;
    setUserDetail(updateUser);
  };

  useEffect(() => {
    const timeOutId = setTimeout(validateOrganizationId, 600);
    return () => clearTimeout(timeOutId);
  }, [userDetail.customCompanyName]);

  const validateOrganizationId = () => {
    const isUserNameValid = getIsUserNameValid(userDetail.customCompanyName);
    const userNameValidLength = userDetail.customCompanyName.length > 5;
    if (
      userDetail.customCompanyName &&
      isUserNameValid &&
      userNameValidLength
    ) {
      userNameValidate(userDetail.customCompanyName)
        .then((result) => {
          setErrorCustomCompanyId("");
          setCompanyIdValid(true);
        })
        .catch(() => {
          setCompanyIdValid(false);
          setErrorCustomCompanyId("Custom Company ID already exits");
        });
    } else if (userDetail.customCompanyName) {
      setCompanyIdValid(false);
      const errrMessage = !isUserNameValid
        ? "Custom Company ID not correct format, It should be numbers, lowercase and  _  only allowed"
        : !userNameValidLength
        ? "Custom Company ID length should be more than 5"
        : "";
      setErrorCustomCompanyId(errrMessage);
    } else {
      setCompanyIdValid(false);
      setErrorCustomCompanyId("");
    }
  };

  const addCustomCompanyId = async () => {
    try {
      setLoaderCompanyName(true);
      const updatedResult = await updateUsername({
        user_name: userDetail.customCompanyName,
      });
      if (updatedResult.data.status) {
        setLoaderCompanyName(false);
        setUpdateCompanyName(1);
        const updateUser = { ...userDetail };
        updateUser.customName = userDetail.customCompanyName;
        setUserDetail(updateUser);
      } else throw new Error("something went wrong! try again");
    } catch (error) {
      setLoaderCompanyName(false);
      setUpdateCompanyName(2);
      setServerErrorMessage(`Oops! Something went wrong. 
                Please try again later. If issue persists, double-check input & retry. For help, contact support.`);
    }
  };

  const hideErrorMessage = () => {
    setServerErrorMessage("");
  };

  const handleSaveLocation = async (e) => {
    e.preventDefault();
    const mockLocation = {
      mock_address: advSetting,
      latitude,
      longitude,
      mock_checkin_type: checkinType,
    };
    setLoader(true);
    try {
      console.log("Request body:", mockLocation);
      const response = await createMockLocation(mockLocation);

      setShowToast({
        isShow: true,
        message: "Mock Location Changed Successfully",
      });

      setLoader(false);
      setIsSaved(true);
      setDisable(true);
      setTimeout(() => {
        setShowToast({
          isShow: false,
          message: "",
        });
      }, 3000);
    } catch (error) {
      setShowToast({
        isShow: true,
        message: "Mock Location Changed Successfully",
      });

      setLoader(false);
      setIsSaved(true);
      setDisable(true);
      setTimeout(() => {
        setShowToast({
          isShow: false,
          message: "",
        });
      }, 3000);
    }
  };

  const handleRemoveLocation = async (e) => {
    e.preventDefault();

    const mockLocation = {};
    setLoader(true);

    try {
      const response = await createMockLocation(mockLocation);
      setLoader(false);
      setAdvSetting("");
      setCheckinType("");
      setLatitude("");
      setLongitude("");
      setShowToast({
        isShow: true,
        message: "Mock Location Removed Successfully",
      });
      setIsSaved(false);
      setDisable(false);
      setTimeout(() => {
        setShowToast({
          isShow: false,
          message: "",
        });
      }, 3000);
    } catch (error) {
      setLoader(false);
      setShowToast({
        isShow: true,
        message: "Mock Location Removed Successfully",
      });
      setIsSaved(false);
      setDisable(false);
      setTimeout(() => {
        setShowToast({
          isShow: false,
          message: "",
        });
      }, 3000);
    }
  };

  const closeToastHandler = () => {
    setShowToast({ isShow: false, message: "" });
  };

  return (
    <Layout>
      <PageHeader title="Settings" />
      {!!serverErrorMessage && (
        <ErrorAlert close={hideErrorMessage}>{serverErrorMessage}</ErrorAlert>
      )}
      <Tap changeTap={changeTap} tapMenu={tapMenu} activeTap={activeTap}>
        <div className="mt-2 mb-4">
          
          {activeTap == "companyInfo" && (
            <div className="px-2">
              {/* <ShowImage src = 'profilePic.png' alt = 'prfilePic'/> */}
              <div className="d-md-flex mt-3 pt-3">
                <div className="col-md-6">
                  <ShowDetails col={basicInformation.col1} value={userDetail} />
                </div>
              </div>
              {
                <div className="col-md-6 row">
                  <div className="col-md-6">
                    <Input
                      type="text"
                      mandatory={false}
                      className={companyIdValid ? "valid-input" : ""}
                      label="Custom Company ID"
                      value={
                        userDetail.customName
                          ? userDetail.customName
                          : userDetail.customCompanyName
                      }
                      helpText={
                        !userDetail.customName && roleName === "admin"
                          ? "numbers, lowercase and  _  only allowed"
                          : ""
                      }
                      onChange={onChangeCustomId}
                      readOnly={
                        userDetail.customName || roleName !== "admin"
                          ? true
                          : false
                      }
                    />
                    <ErrorMessage label={errorCustomCompanyId} />
                  </div>
                  {!userDetail.customName && roleName === "admin" && (
                    <div className="col-md-6">
                      <Button
                        className="btn-primary btn-sm mt-30 settings-button"
                        onClick={addCustomCompanyId}
                        disabled={
                          isLoaderCompanyName ||
                          errorCustomCompanyId ||
                          !companyIdValid
                        }
                      >
                        <i className="bi bi-pencil-fill mr-5"></i>
                        {isLoaderCompanyName ? "Loading..." : "Add Company ID"}
                      </Button>
                    </div>
                  )}
                  {updateCompanyName ? (
                    updateCompanyName === 1 ? (
                      <div className="alert alert-success mt-10" role="alert">
                        Custom Company Id added successfully!
                      </div>
                    ) : (
                      <div className="alert alert-danger mt-10" role="alert">
                        Custom Company Id has not been added, Please try again
                      </div>
                    )
                  ) : null}
                </div>
              }
              <div className="col-md-6 row">
                <div className="col-md-6">
                  <Select
                    mandatory={false}
                    keyValue={true}
                    label="Timezone"
                    value={userDetail.timezone}
                    onChange={onChangeTimezone}
                    options={timezoneList}
                  />
                </div>
                <div className="col-md-6">
                  <Button
                    className="btn-primary btn-sm mt-30 settings-button"
                    onClick={updateTimezoneHandler}
                    disabled={isLoader}
                  >
                    <i className="bi bi-pencil-fill mr-5"></i>
                    {isLoader ? "Loading..." : "Update timezone"}
                  </Button>
                </div>
                {updateTimezoneStatus ? (
                  updateTimezoneStatus === 1 ? (
                    <div className="alert alert-success mt-10" role="alert">
                      Timezone updated successfully!
                    </div>
                  ) : (
                    <div className="alert alert-danger mt-10" role="alert">
                      Timezone not updated, Please try again
                    </div>
                  )
                ) : null}
              </div>
            </div>
          )}
          {activeTap == "password" && (
            <div className="d-md-flex justify-content-center">
              <div className="col-md-4">
                <h6 className="mb-2">Change Password</h6>
                <Form
                  className="col"
                  rows={passwordFields}
                  value={passwords}
                  errorMessage={passwordError}
                  onChange={passwordChangeHandler}
                />
                <Button
                  className="btn-primary w-100 f-s14"
                  onClick={savePassword}
                  disabled={isLoader}
                >
                  {isLoader ? "Loading..." : "Save"}
                </Button>
                {updatePasswordRequest ? (
                  updatePasswordRequest === 1 ? (
                    <div className="alert alert-success mt-10" role="alert">
                      Password updated successfully!
                    </div>
                  ) : (
                    <div className="alert alert-danger mt-10" role="alert">
                      Invalid Password, Please try with right one
                    </div>
                  )
                ) : null}
              </div>
            </div>
          )}
          {activeTap === "advancedSettings" && isOrganizationAllowed && (
            <div className="d-flex flex-column justify-content-center align-items-center">
              <div className="map-autocomplete mb-3 p-8 row justify-content-center">
                <label className="label mb-1">Mock Location</label>
                <AddressInput
                  address={advSetting || " "}
                  onChange={(address, lat, lng) =>
                    onChangeAdvSetting(address, lat, lng)
                  }
                  
                />
                <label className="label mb-1 mt-3">CheckIn Type</label>
                <input
                  className="p-2"
                  type="text"
                  value={checkinType || ""}
                  onChange={(e) => setCheckinType(e.target.value)}
                  placeholder="Enter checkin type"
                ></input>
              </div>
              <div>
                {errorMessage.address && (
                  <div className="error-message mt-0">
                    {errorMessage.address}
                  </div>
                )}
              </div>
              <div className="d-flex justify-content-center gap-2">
                {isLoader ? (
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <>
                    {
                      <Button
                        className="btn-danger btn-sm settings-button"
                        onClick={handleRemoveLocation}
                      >
                        Clear
                      </Button>
                    }
                    {
                      <Button
                        className="btn-primary btn-sm settings-button"
                        onClick={handleSaveLocation}
                      >
                        Save
                      </Button>
                    }
                  </>
                )}
              </div>
            </div>
          )}
          {showToast.isShow && (
            <Toast message={showToast.message} closeToast={closeToastHandler} />
          )}

          {activeTap == "subscription" && (
            <div className="d-md-flex align-items-stretch">
              {subscriptionPlans.map((plan, index) => {
                return (
                  <div key={index} className="col px-3 mt-3 mt-md-0">
                    <div className="card h-100">
                      <div className="card-body d-flex flex-column justify-content-between">
                        <div>
                          <div className="text-center">
                            <div className="f-w-600 f-s16 text-uppercase">
                              {plan.planName}
                            </div>
                            <h3 className="f-w-bold m-0 mt-2">
                              {plan.planPrice}
                            </h3>
                            <div className="text-secondary f-s14">
                              {plan.subText}
                            </div>
                          </div>
                          <div className="border-top mt-2 mb-4">
                            <div className="mt-3">
                              {plan.planFeature.map(
                                (featureList, listIndex) => {
                                  const className = plan.activated
                                    ? "text-success"
                                    : "text-primary";
                                  return (
                                    <div className="mt-1 f-s16" key={listIndex}>
                                      <span
                                        className={
                                          "bi bi-check-circle-fill mr-5 " +
                                          className
                                        }
                                      ></span>
                                      <span className="f-w-600">
                                        {featureList}
                                      </span>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-stretch">
                          {plan.button && (
                            <Button className="btn-primary w-100 pt-1 pb-1 f-s16">
                              {plan.button}
                            </Button>
                          )}

                          {plan.activated && (
                            <div className=" rounded text-center w-100 bg-success text-white w-100 pt-1 pb-1 f-s16">
                              {plan.activated}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </Tap>

      {status.showStatus && (
        <Modal>
          <div className="mb-3">
            <div
              className={
                status.showStatus ? "d-block status-spinner" : "d-none"
              }
            >
              <Status
                spinner={status.spinner}
                success={status.success}
                successMessage={status.successMessage}
              />
            </div>
          </div>
        </Modal>
      )}
    </Layout>
  );
};
export default Settings;
