import {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
//components
import Input from '../Components/Common/Input';
import Button from '../Components/Common/Button' ;
import StepProgressBar from '../Components/StepProgressBar' ;
import FormHeader from '../Components/Common/FormHeader' ;
import SecondaryHeader from '../Components/SecondaryHeader' ;
import FormControl from '../Components/Common/FormControl' ;
import ErrorMessage from '../Components/Common/ErrorMessage' ;
//services
import { activateAccount } from '../services/auth' ;

const SignupOtpVerify=(props)=>{
    const router = useHistory();
    useEffect(()=>{
        if(sessionStorage.getItem ( 'email' ) == null) router.push('./Signup');
    },[true])

        const initialState={
            formFields:{
                otp:""
            },
            errorMessage:{
                otp:""
            }
        }
        const[state,setState]=useState(initialState);
        const [isLoading, setLoading] = useState(false);
        const onChangeHandler=(event)=>{
            setState({...state,formFields:{otp:event.target.value},errorMessage:{otp:""}})      
        }
        // welcome page state
        const [showActivate, setActivate] = useState(false);
        const onClickHandler=(event)=>{
            let errorMessage = state.errorMessage
            if ( !state.formFields.otp ) {
                setState({...state,errorMessage:{otp:"Please enter OTP"}})
            }
            else{
                const data = { mail_id : sessionStorage.getItem ('email'), otp : state.formFields.otp }
                setLoading(true);
                    activateAccount ( data ).then ( (results) => {
                        // welcome page state
                        setActivate (true)
                        localStorage.setItem ( 'token', results.data.token );
                        setTimeout (()=>{
                            router.push ('/NewBranch')
                        },2000)
                    }).catch ( (error) => {
                        setLoading(false);
                        if ( error && error.response ) {
                            errorMessage.otp = error.response.data.data
                            setState ( {...state, errorMessage } )
                            
                            // errorMessage.commonError = error.response.data.error;
                        }
                        setState ( {...state, errorMessage: { otp: "There is an error, please try with valid input or try later" } } )
                    } )
            }
        }
        
    return (
            <>
            <SecondaryHeader/>
            
            <div className="container-xxl">
                {!showActivate && 
                <div className="col-md-6 offset-md-3 mt-1">
                    <div>
                        <StepProgressBar/>
                    </div>
                    <div className="col-md-6 border offset-md-3 pt-3 pb-3 px-3 mt-5">
                        <FormHeader heading="Verify Your Account" subHeading="We send OTP to your Email"/>
                        <FormControl>
                            <Input type = "text" label = "Enter OTP" value = {state.formFields.otp} onChange = {onChangeHandler} />
                            <ErrorMessage label={state.errorMessage.otp}/>
                        </FormControl>
                        <Button className = "btn-primary w-100" onClick = {onClickHandler} disabled={isLoading}>{isLoading ? "Loading..." : "Verify"}</Button>
                        {/* <div className="text-center">
                            <Button className = "btn-link mt-3">Resend OTP</Button>
                        </div> */}
                    </div>
                </div>
                }

                { showActivate && 
                    <div className = 'd-flex flex-column justify-content-center align-items-center mt-5 pt-3 '>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="#198754" className = "bi bi-check2-circle" viewBox="0 0 16 16" >
                                <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"/>
                                <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/>
                            </svg>
                        </div>
                        <h2 className = 'text-success' >Your account successfully activated</h2>
                        <div className = 'text-muted f-s14'>page redirect in 2sec</div>
                    </div> 
                }
            </div>
            </>
    )
}
export default SignupOtpVerify