import React, { useEffect, useState } from "react";
import {
  Switch,
  Route,
  useLocation
} from "react-router-dom";
import { App as CapacitorApp } from '@capacitor/app';
import PublicRoute from "./PublicRouter";
import Login from '../Pages/Login';
import ForgetPassword from '../Pages/ForgetPassword';
import ForgetChoosePassword from '../Pages/ForgetChoosePassword';
import Signup from '../Pages/Signup';
import First from '../Pages/First';
import SignupOtpVerify from '../Pages/SignupOtpVerify';
import Logged from '../Components/Logged';



export default () => {

  useEffect(() => {
    const backHandler = (a) => {
      const pageName = localStorage.getItem("pageName");
      if (a.canGoBack && pageName !== "checkInQR") {
        window.history.back();
      } else if (pageName !== "checkInQR") {
        CapacitorApp.exitApp();
      } else {
        if (window.stopScan) window.stopScan(true);
      }
    };

    CapacitorApp.addListener('backButton', backHandler);
    return () => {
      CapacitorApp.removeListener('backButton', backHandler);
    };
  }, []);

  return (
        <Switch>
          <PublicRoute component={First} path="/first" restricted={true} />
          <PublicRoute component={Signup} path="/signup" restricted={true} />
          <PublicRoute component={Login} path="/login" restricted={true} />
          <PublicRoute component={ForgetPassword} path="/ForgotPassword" restricted={true} />
          <PublicRoute component={ForgetChoosePassword} path="/ResetPassword" restricted={true} />
          <PublicRoute component={SignupOtpVerify} path="/SignupOtpVerify" restricted={true} />
          <Route component={Logged} path="/" />
        </Switch>
  );
}
