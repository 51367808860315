import React, { useState, useRef, useEffect, createContext } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import { adminNavItems } from "../constant/navItems";
import { getMenus } from "../services/menu.js";
import { logout } from "../Utils/login";
import { userDetailsContext } from "../context";
import { Capacitor } from "@capacitor/core";
import CheckInAdmin from "../Pages/CheckinAdmin.js";
import DashBoard from "../Pages/Dashboard.js";
import { getActivity } from "../services/dashboard.js";
import useRedirect from "../Utils/useRedirect.js";
import { useContext } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useLocation } from "react-router-dom/cjs/react-router-dom.js";

const Logged = () => {
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState("");
  const [isSetupNotCompleted, setSetupCompleted] = useState(-1);
  const [activities, setActivities] = useState([]);
  const [userDetails, setUserDetails] = useState({ menu: [] });

  const location = useLocation();

  useEffect(() => {
    getActivity()
      .then((results) => {
        const {
          data: { result },
        } = results;
        setActivities(result);
        const setupNotCompleted = result.some((item) => {
          return !item.iscompleted;
        });
        setSetupCompleted(setupNotCompleted ? 1 : 0);
      })
      .catch((error) => {
        if (error && error.response && error.response.status === 400) {
          setSetupCompleted(0);
        } else {
          setErrorMessage(`Oops! Something went wrong. 
                            Please try again later. If issue persists, double-check input & retry. For help, contact support.`);
        }
      });
  }, []);

  useEffect(() => {
    getMenus()
      .then((result) => {
        const data = result.data;
        data.menu.push("newBranch");
        setUserDetails(data);
      })
      .catch((error) => {
        if (error && error.response && error.response.status === 401) {
          logout();
          history.push("/First");
        } else {
          logout();
          history.push("/First");
        }
      });
  }, []);

  const platformName = Capacitor.getPlatform();
  const isRestrictPage = localStorage.getItem("isRestrictPage");

  const filteredMenuItems = adminNavItems.filter((item) => {
    if (item.router === "/Checkin" || item.router === "/CheckinAdmin") {
      const showCheckin =
        item.router === "/CheckinAdmin"
          ? platformName === "android" || platformName === "ios"
          : (userDetails.gpsAllowed &&
              (platformName === "android" || platformName === "ios")) ||
            !userDetails.gpsAllowed;
      return item.page.includes(userDetails.role) && showCheckin;
    } else {
      return isRestrictPage === "true" ? false : true;
    }
  });

  const filteredMenuFromUserDetails = userDetails.menu.filter((routerName) => {
    if (routerName === "checkin" || routerName === "checkinAdmin") {
      const showCheckin =
        routerName === "checkinAdmin"
          ? platformName === "android" || platformName === "ios"
          : (userDetails.gpsAllowed &&
              (platformName === "android" || platformName === "ios")) ||
            !userDetails.gpsAllowed;
      return showCheckin;
    }
    return true;
  });

  const filteredUserDetails = {
    ...userDetails,
    menu: filteredMenuFromUserDetails,
    activitiesresult: activities,
  };

  const renderRouter = () => {
    const pageRestrict =
      isRestrictPage === "true" &&
      filteredMenuFromUserDetails.includes("checkinAdmin");
    return (
      <userDetailsContext.Provider value={filteredUserDetails}>
         <TransitionGroup>
          <CSSTransition
            key={location.key}
            timeout={500}
            classNames="slide-page"
            unmountOnExit
          > 
            <Switch location={location}>
              {filteredMenuItems.map(({component:Component, router},index) => {
                const props= {setActivities,activities}
                return <Route key={index} path={router} render={(routeProps) => (
                    <Component {...routeProps} {...props} />
                  )}/>;
              })}
              
              <Route
                path="*"
                component={pageRestrict ? CheckInAdmin : DashBoard}
              />
              {/* <Redirect exact from={ "/"} to={pageRestrict ? "/CheckinAdmin" : "/CheckinAdmin"} /> */}
            </Switch>
           </CSSTransition>
        </TransitionGroup> 
      </userDetailsContext.Provider>
    );
  };

  return <React.Fragment>{renderRouter()}</React.Fragment>;
};

export default Logged;
