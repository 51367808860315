import Login from '../Pages/Login';
import Dashboard from '../Pages/Dashboard';
import Employees from '../Pages/Employees';
import AddEmployee from '../Pages/AddEmployee';
import EditEmployee from '../Pages/EditEmployee';
import EmployeeDetails from '../Pages/ViewEmployee';
import Attendance from '../Pages/Attendance';
import Designation from '../Pages/Designation';
import Branch from '../Pages/Branch';
import Team from '../Pages/Team';
import Shift from '../Pages/Shift';
import Leave from '../Pages/Leave';
import HoliDays from '../Pages/HoliDays';
import HolidaysManager from '../Pages/HolidaysManager';
import Reports from '../Pages/Reports';
import Settings from '../Pages/Settings';
import SignupOtpVerify from '../Pages/SignupOtpVerify';
import CheckinEmployee from '../Pages/CheckinEmployee';
import CheckInAdmin from '../Pages/CheckinAdmin';
import About from '../Pages/About';
import NewBranch from '../Pages/NewBranch';
import NewEmployee from '../Pages/NewEmployee';

export const adminNavItems = [
    { router : '/AddEmployee', component: AddEmployee},
    { router : '/NewBranch', component: NewBranch}, 
    { router : '/NewEmployee', component: NewEmployee},
    { router : '/EditEmployee/:id', component: AddEmployee},
    { router : '/EmployeeDetails/:id', component: EmployeeDetails},
    //{ router : '/SignupOtpVerify', component: SignupOtpVerify},
    { label : "DashBoard", icon : "bi bi-speedometer", router : '/Dashboard', component: Dashboard, name:"dashboard" },
    { label : "Employees", icon : "bi bi-person-check-fill", router : '/Employees', component: Employees  },
    { label : "Scan QR Kiosk", icon : "bi bi-speedometer", page:['admin', 'branchLead', 'teamLead'], name: 'checkinAdmin', 
    router : '/CheckinAdmin', component: CheckInAdmin },
    { label : "Checkin", icon : "bi bi-speedometer", page: ['employee', 'branchLead', 'teamLead'], router : '/Checkin', component: CheckinEmployee },
    { label : "Timesheet", icon : "bi bi-card-checklist", router : '/Attendance', component: Attendance  },
    { label : "Designation", icon : "bi bi-card-checklist", router : '/Designation', component: Designation  },
    { label : "Branch", icon : "bi bi-building", router : '/Branch', component: Branch  },
    { label : "Team", icon : "bi bi-diagram-3-fill", router : '/Team', component: Team  },
    { label : "Shift", icon : "bi bi-diagram-3-fill", router : '/Shift', component: Shift  },
    { label : "Leave", icon : "bi bi-diagram-3-fill", router : '/Leave', component: Leave  },
    { label : "HoliDays", icon : "bi bi-diagram-3-fill", router : '/HoliDays', component: HoliDays  },
    { label : "Holidays Manager", icon : "bi bi-diagram-3-fill", router : '/HolidaysManager', component: HolidaysManager  },
    { label : "Reports", icon : "bi bi-diagram-3-fill", router : '/Reports', component: Reports  },
    { label : "Settings", icon : "bi bi-gear-fill", router : '/Settings', component: Settings  },
    { label : "Support", icon : "bi bi-info-square-fill", router : '/Support', component: About  },
    { router : '/', component: Dashboard },
]

export const menuItems = {
   addEmployee: { router : '/AddEmployee', component: AddEmployee},
   newBranch: { router : '/NewBranch', component: NewBranch},
   newEmployee: { router : '/NewEmployee', component: NewEmployee},
   editEmployee: { router : '/EditEmployee', component: EditEmployee},
   employeeDetails: { router : '/EmployeeDetails', component: EmployeeDetails},
   signupOtpVerify: { router : '/SignupOtpVerify', component: SignupOtpVerify},
   dashboard: { label : "DashBoard", icon : "bi bi-speedometer", router : '/Dashboard', component: Dashboard },
   employees: { label : "Employees", icon : "bi bi-person-check-fill", router : '/Employees', component: Employees  },
   checkin: { label : "Checkin", icon : "bi bi-check2-circle", router : '/Checkin', component: CheckinEmployee },
   attendance: { label : "Timesheet", icon : "bi bi-card-checklist", router : '/Attendance', component: Attendance  },
   designation: { label : "Designation", icon : "bi bi-briefcase", router : '/Designation', component: Designation  },
   branch: { label : "Branch", icon : "bi bi-building", router : '/Branch', component: Branch  },
   team: { label : "Team", icon : "bi bi-diagram-3-fill", router : '/Team', component: Team  },
   shift: { label : "Shift", icon : "bi bi-clock-history", router : '/Shift', component: Shift  },
   leave: { label : "Leave", icon : "bi bi-calendar-x", router : '/Leave', component: Leave  },
   holiDays: { label : "HoliDays", icon : "bi bi-diagram-3-fill", router : '/HoliDays', component: HoliDays  },
   holidaysManager: { label : "Holidays Manager", icon : "bi bi-diagram-3-fill", router : '/HolidaysManager', component: HolidaysManager  },
   reports: { label : "Reports", icon : "bi bi-diagram-3-fill", router : '/Reports', component: Reports  },
   checkinAdmin: { label : "Scan QR Kiosk", icon : "bi bi-check2-circle", router : '/CheckinAdmin', component: CheckInAdmin },
   settings: { label : "Settings", icon : "bi bi-gear-fill", router : '/Settings', component: Settings  },
   support: { label : "Support", icon : "bi bi-info-square-fill", router : '/Support', component: About  }
}


export const employeeNavItems = [
    { router : '/Login', component: Login },
    { label : "Checkin", icon : "bi bi-speedometer", router : '/Checkin', component: CheckinEmployee },
    { label : "Settings", icon : "bi bi-gear-fill", router : '/Settings', component: Settings  }
]


export const sideMenuList = ["/Dashboard", "/Employees", "/Attendance", "/Designation", "/Branch", "/Team",
"/Shift", "/Leave", "/HoliDays", "/HolidaysManager", "/Reports", "/Settings", "/Checkin"];