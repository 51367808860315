import { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import Layout from "../Components/Layout";
import PageHeader from "../Components/Common/PageHeader";
import Modal from "../Components/Common/Modal";
import ModalFooter from "../Components/Common/ModalFooter";
import Button from "../Components/Common/Button";
import Pazhanam from "../images/pazhanam.png";
import { logout } from "../Utils/login";
import { deleteAccount } from "../services/employee";
import { delete_message } from "../constant/deleteMessage";
import { userDetailsContext } from "../context";
import useRedirect from "../Utils/useRedirect";

const About = () => {
  const [isShowModal, setIsShowModal] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const history = useHistory();
  const { role } = useContext(userDetailsContext);
  const { activitiesresult = [] } = useContext(userDetailsContext);

  useRedirect(activitiesresult);

  const toggleModal = () => setIsShowModal((prev) => !prev);

  const [isLoading, setIsLoading] = useState(false); // New state for loading

  const handleDeleteAccount = async (e) => {
    e.preventDefault();
    setIsLoading(true); 

    try {
      await deleteAccount();
      setShowSuccess(true);
      setIsShowModal(false);
      logout();
    } catch (error) {
      setShowSuccess(true);
      setIsShowModal(false);
      logout();
    } 
  };

  if (!role) {
    return (
      <Layout>
        <PageHeader title="Support" />
        <div className="container text-center">
          <div className="spinner"></div>
        </div>
      </Layout>
    );
  }

  const DeleteSection = () => {
    if (role === "admin") {
      return (
        <div className="card mt-30">
          <div className="card-body">
            <h5 className="card-title">Delete This Account:</h5>
          </div>
          <div className="card-body">
            <button className="btn btn-danger mt-20" onClick={toggleModal}>
              Delete Account
            </button>
          </div>
        </div>
      );
    }
    return (
      <div className="card mt-30">
        <div className="card-body">
          <h5 className="card-title">Delete Your Account:</h5>
        </div>
        <div className="card-body">
          To delete your account, email <strong>support@thecheckin.io</strong>{" "}
          from your registered email with the subject{" "}
          <strong>Account Deletion Request</strong>. Include your name and
          registered email in the message. The process may take 5-7 business
          days, and data will be permanently deleted.
        </div>
      </div>
    );
  };

  return (
    <Layout>
      <PageHeader title="Support" />
      <div className="container">
        {DeleteSection()}

        {/* Confirm Delete Modal */}
        {isShowModal && !showSuccess && (
          <Modal title="Confirm Delete" hideModal={toggleModal}>
            <p>{delete_message}</p>
            <ModalFooter alignment="end">
              <Button className="btn btn-secondary" onClick={toggleModal}>
                Cancel
              </Button>
              <Button
                className="btn btn-danger"
                onClick={handleDeleteAccount}
                disabled={isLoading} 
              >
                {isLoading ? "Loading..." : "Confirm"}
              </Button>
            </ModalFooter>
          </Modal>
        )}

        {/* Success Modal */}
        {showSuccess && (
          <Modal
            title="Account Deleted"
            hideModal={() => history.push("/First")}
          >
            <p>Your account has been deleted successfully.</p>
            <ModalFooter alignment="end">
              <Button
                className="btn btn-primary"
                onClick={() => {
                  logout();
                  history.push("/First"); // Redirect and logout after success
                }}
              >
                OK
              </Button>
            </ModalFooter>
          </Modal>
        )}

        <div className="card mt-30">
          <div className="text-center">
            <img src={Pazhanam} className="pazhanam-logo" alt="Pazhanam Logo" />
          </div>
          <div className="card-body">
            <h5 className="card-title">About Us:</h5>
            <h5 className="card-title">Pazhanam Technologies Pvt Ltd</h5>
            <p className="card-text">
              We are dedicated to building innovative and user-friendly
              applications from India.
            </p>
            <p className="card-text">
              Our passion drives us to create products that simplify your life.
            </p>
          </div>
          <div className="card-body">
            If you need support or have feedback for TheCheckIn app, please
            contact us at <strong>support@thecheckin.io</strong>.
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default About;
