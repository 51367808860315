import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import GoogleMap from "../Components/Common/Map";
import Actions from "../Components/Common/Actions";
import Status from "../Components/Common/Status";
import { isValidNumber } from "../Utils/isValidNumber";
import { createBranch as createBranchService } from "../services/branch";
import QRCodeGenerator from "../Components/Common/QRCodeGenerator";
import PageHeader from "../Components/Common/PageHeader";
import "./style.css";
import { logout } from "../Utils/login";
import router from "../router";
import useAccess from "../Utils/useAccess";
import { getActivity } from "../services/dashboard"; // Import activity API

const NewBranch = ({ setActivities }) => {
  const history = useHistory();
  const [branch, setBranch] = useState({ name: "" });
  const [errorMessage, setErrorMessage] = useState({});
  const [branchAddress, setBranchAddress] = useState({});
  const [branchQR, setBranchQR] = useState();
  const [isLoading, setIsLoading] = useState(false);

  //const { role } = useContext(userDetailsContext);

  const setCompletedStatus = (statusValue) => {
    const updatedStatus = { ...status };
    if (statusValue) {
      updatedStatus.success = true;
      updatedStatus.successMessage = "Branch was already created successfully";
    }
    setStatus({
      ...updatedStatus,
      showStatus: statusValue,
      intialrender: true,
    });
  };

  useAccess(setCompletedStatus);
  const [status, setStatus] = useState({
    showStatus: false,
    spinner: false,
    success: false,
    successMessage: "",
    intialrender: false,
  });

  const validateInputFields = () => {
    const validationResult =
      branch && branch.name
        ? { isValid: true, errorMessage: {} }
        : { errorMessage: { branch: "Please check valid branch name" } };
    let newErrorMessage = validationResult.errorMessage;
    let isValid = validationResult.isValid;

    if (
      !branchAddress.latitude ||
      !branchAddress.longitude ||
      !branchAddress.address
    ) {
      isValid = false;
      newErrorMessage.address =
        "Please select address using map or autocomplete";
    }
    if (!branchAddress.circle || !isValidNumber(branchAddress.circle)) {
      isValid = false;
      newErrorMessage.circle = "Numbers only allowed";
    }
    setErrorMessage({ ...newErrorMessage });
    return isValid;
  };

  const branchData = () => {
    const { name } = branch;
    const { latitude, longitude, address, circle } = branchAddress;
    return { name, latitude, longitude, address, circle };
  };

  const addBranch = async () => {
    if (validateInputFields()) {
      const data = branchData();
      setIsLoading(true);
      setStatus({ ...status, showStatus: false, spinner: false });

      try {
        const results = await createBranchService(data);
        setBranchQR(results.data.qr_code_id || "");

        setStatus({
          showStatus: true,
          spinner: false,
          success: true,
          successMessage: "Branch created successfully",
          intialrender: true,
        });
      } catch (error) {
        console.error("Error creating branch:", error);
        setBranchQR("");
        setStatus({
          showStatus: true,
          spinner: false,
          success: false,
          successMessage: "Error while creating branch",
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const changeLatLng = (lat, lng, currentaddress, radius) => {
    setBranchAddress({
      latitude: lat,
      longitude: lng,
      address: currentaddress,
      circle: radius,
    });
    setErrorMessage({ ...errorMessage, address: "" });
  };

  const changeCircle = (event) => {
    const value = event.target.value;
    setBranchAddress({ ...branchAddress, circle: value });
    if (isValidNumber(value)) {
      setErrorMessage({ ...errorMessage, circle: "" });
    }
  };

  const signout = () => {
    logout();
    history.push("/First");
  };

  const actionButtons = [
    {
      label: isLoading ? "Loading.." : "Save",
      className: "btn-primary",
      onClick: addBranch,
      disabled: isLoading,
    },
  ];

  console.log({ status });

  return (
    <div className="card shadow-sm">
      <PageHeader title="Add New Branch" showBackButton={false} />
      <div className="card-body">
        {status.intialrender ? (
          !status.showStatus ? (
            <>
              <div className="row mb-4">
                <div className="col-lg-6">
                  <label className="form-label">
                    Name <span className="text-danger">*</span>
                  </label>
                  <input
                    className={`form-control ${
                      errorMessage.branch ? "is-invalid" : ""
                    }`}
                    type="text"
                    value={branch.name || ""}
                    onChange={(e) =>
                      setBranch({ ...branch, name: e.target.value })
                    }
                    autoComplete="off"
                  />
                  {errorMessage.branch && (
                    <div className="invalid-feedback">
                      {errorMessage.branch}
                    </div>
                  )}
                </div>
              </div>
              <GoogleMap
                latitude={branchAddress.latitude}
                longitude={branchAddress.longitude}
                address={branchAddress.address}
                circle={branchAddress.circle}
                changeLatLng={changeLatLng}
                changeCircle={changeCircle}
                addressError={errorMessage.address}
                circleError={errorMessage.circle}
              />
              <div className="mt-4">
                <div className="d-flex justify-content-end mt-4">
                  <Actions actions={actionButtons} />
                </div>
              </div>
            </>
          ) : (
            <Status
              spinner={status.spinner}
              success={status.success}
              successMessage={status.successMessage}
              buttonLabel="Add Employee"
              onClick={async () => {
                const updatedActivity = await getActivity();
                setActivities(updatedActivity.data.result);
                setStatus({ showStatus: false });
                history.push("/NewEmployee");
              }}
            >
              <style>
                {`
                  .d-block .btn-secondary {
                    background-color: #1d19d8 !important;
                    border-color: #1d19d8 !important;
                    color: white !important;
                  }
                `}
              </style>
              <style>
                {`
                  .d-flex .btn-primary {
                    background-color: #6c757d !important;
                    border-color: #6c757d !important;
                    color: white !important;
                  }
                `}
              </style>

              {branchQR && (
                <>
                  <p>
                    1) Download the Branch QR provided below.
                    <br />
                    2) Fix the QR code to the office entrance wall. Employees
                    should scan it to check-in/check-out (CheckIn type: Branch
                    QR).
                  </p>
                  <div className="d-flex justify-content-center mb-3">
                    <QRCodeGenerator
                      size={200}
                      value={branchQR}
                      download={true}
                      regenerate={false}
                      title={""}
                    />
                  </div>
                </>
              )}
            </Status>
          )
        ) : null}
      </div>
    </div>
  );
};

export default NewBranch;
